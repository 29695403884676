:root {
  --text-primary: #e8eaed;
  --text-primary-contrast: #fff;
  --text-primary-blink: #e8eaed7f;
  --text-primary-disabled: #6d6d6d;
  --text-secondary: #ababab;
  --text-dim: #949498;
  --primary: #0f0f10;
  --primary-transparent: #0f0f10cc;
  --secondary: #202124;
  --tertiary: #2d3036;
  --selected: #64b5f60d;
  --secondary-variant: #24252a;
  --asset: #00c46a;
  --contrast: #64b5f6;
  --info: #64b5f633;
  --info-blink: #64b5f616;
  --info-variant: #2e3e4e;
  --info-highlighted: #465666;
  --warning: #f7ca4733;
  --warning-blink: #f7ca4716;
  --live: #a42416;
  --alert: #a4241633;
  --alert-tooltip: #ffbb0c;
  --alert-tooltip-bg: #4d311e;
  --alert-blink: #a4241616;
  --success: #00c46a1a;
  --active: #303e4f;
}

/* Make app full-height */
html,
body,
#root {
  height: 100%;
}

#root {
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary);
  color: var(--text-primary);
  font-size: 14px;
}

h2 {
  font-size: 16px;
  margin-bottom: 16px;
}

p {
  color: var(--text-secondary);
}

/* hide dev server overlay to get rid of "ResizeObserver loop limit exceeded" */
#webpack-dev-server-client-overlay {
  display: none;
}

.react-tooltip {
  z-index: 1000;
}
