/**
 Styles for asanplayer library
 */
.streamwarning {
  top: 31%;
  width: 20%;
  position: absolute;
  background-color: var(--tertiary);
  padding: 10px;
  min-width: 260px;
  text-align: center;
  opacity: 0.7;
}
