.react-datetime-picker {
  display: block;
  position: relative;
  width: 100%;
}
.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-datetime-picker--disabled,
.react-datetime-picker--disabled .react-datetime-picker__inputGroup__input {
  color: var(--text-primary-disabled);
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border-radius: 4px;
  background: var(--tertiary);
  padding: 6.5px 12px;
}
.react-datetime-picker__inputGroup {
  width: 100%;
  height: 100%;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  color: var(--text-primary);
  outline: 0;
}
.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:enabled:hover
  .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus
  .react-datetime-picker__button__icon {
  stroke: #0078d7;
}
.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: var(--text-primary-disabled);
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__button svg rect {
  stroke: var(--text-primary);
}
.react-datetime-picker__button svg line {
  fill: var(--text-primary);
  stroke: var(--text-primary);
}

.react-datetime-picker__button:disabled svg rect {
  stroke: var(--text-primary-disabled);
}

.react-datetime-picker__button:disabled svg line {
  fill: var(--text-primary-disabled);
  stroke: var(--text-primary-disabled);
}

.react-datetime-picker__calendar {
  width: 300px;
  max-width: 100vw;
  position: absolute;
  padding: 8px 0;
  left: 0;
  z-index: 10;
}
.react-datetime-picker__calendar--closed {
  display: none;
}

/* ================== react-calendar ================== */

.react-calendar {
  width: 300px;
  max-width: 100%;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.125em;
  background: var(--tertiary);
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  padding: 10px 0;
  height: 45px;
  display: flex;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: bold;
}
.react-calendar__navigation button:enabled:hover {
  background: var(--selected);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: var(--text-primary);
}
.react-calendar__tile:disabled {
  background-color: #202124;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--selected);
}
.react-calendar__tile--now {
  background: #20212466;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: var(--selected);
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar--selectRange
  .react-calendar__tile--hover:not(.react-calendar__tile--rangeStart),
.react-calendar--selectRange
  .react-calendar__tile--hover:not(.react-calendar__tile--rangeEnd) {
  background: var(--selected);
}
.react-calendar__tile--range {
  background: var(--selected);
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: var(--info);
}
